import "./App.css";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Navbar from "./components/Navbar";
import axios from "axios";
import Sidenav from "./components/Sidenav";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  const [Data, setData] = useState([]);
  const [Currency, setCurrency] = useState("EURUSD");
  const [Period, setPeriod] = useState({
    name: "Month",
    code: "2628000",
    symbol: "M",
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data: apiData } = await axios.get(
        `https://api.forexfeed.net/data/163965017281586/n-100/i-${Period.code}/s-${Currency}/f-json/df-ymd`
      );
      const data = apiData.data[Currency];
      const formatedData = data.map((obj) => ({
        x: obj.time,
        y: [obj.open, obj.high, obj.low, obj.close],
      }));
      console.log(formatedData);
      setData(formatedData);
    };
    fetchData();
  }, [Currency, Period]);

  const chart = {
    series: [
      {
        data: Data,
      },
    ],
    options: {
      chart: {
        type: "candlestick",
        height: 350,
      },
      title: {
        text: `Forex ${Currency} Chart`,
        align: "left",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
    },
  };

  const handleCurrencyChange = (currency) => {
    setCurrency(currency);
  };

  const handlePeriodChange = (period) => {
    setPeriod(period);
  };

  return (
    <div className="App">
      <Navbar period={Period} onSelect={handlePeriodChange} />
      <div className="row my-2">
        <div className="col-9">
          <div id="chart">
            <ReactApexChart
              options={chart.options}
              series={chart.series}
              type="candlestick"
              height={650}
            />
          </div>
        </div>
        <div id="table" className="col">
          <Sidenav currency={Currency} onSelect={handleCurrencyChange} />
        </div>
      </div>
    </div>
  );
}
export default App;
