const currency = [
    "AUDCAD",
    "AUDCHF",
    "AUDCNY",
    "AUDCZK",
    "AUDDKK",
    "AUDEUR",
    "AUDGBP",
    "AUDHKD",
    "AUDHUF",
    "AUDINR",
    "AUDJPY",
    "AUDMXN",
    "AUDNOK",
    "AUDNZD",
    "AUDPLN",
    "AUDSAR",
    "AUDSEK",
    "AUDSGD",
    "AUDTHB",
    "AUDTRY",
    "AUDTWD",
    "AUDUSD",
    "AUDXAG",
    "AUDXAU",
    "AUDZAR",
    "CADAUD",
    "CADCHF",
    "CADCNY",
    "CADCZK",
    "CADDKK",
    "CADEUR",
    "CADGBP",
    "CADHKD",
    "CADHUF",
    "CADINR",
    "CADJPY",
    "CADMXN",
    "CADNOK",
    "CADNZD",
    "CADPLN",
    "CADSAR",
    "CADSEK",
    "CADSGD",
    "CADTHB",
    "CADTRY",
    "CADTWD",
    "CADUSD",
    "CADXAG",
    "CADXAU",
    "CADZAR",
    "CHFAUD",
    "CHFCAD",
    "CHFCNY",
    "CHFCZK",
    "CHFDKK",
    "CHFEUR",
    "CHFGBP",
    "CHFHKD",
    "CHFHUF",
    "CHFINR",
    "CHFJPY",
    "CHFMXN",
    "CHFNOK",
    "CHFNZD",
    "CHFPLN",
    "CHFSAR",
    "CHFSEK",
    "CHFSGD",
    "CHFTHB",
    "CHFTRY",
    "CHFTWD",
    "CHFUSD",
    "CHFXAG",
    "CHFXAU",
    "CHFZAR",
    "CNYAUD",
    "CNYCAD",
    "CNYCHF",
    "CNYEUR",
    "CNYGBP",
    "CNYHKD",
    "CNYJPY",
    "CNYSGD",
    "CNYUSD",
    "CZKAUD",
    "CZKCAD",
    "CZKCHF",
    "CZKEUR",
    "CZKGBP",
    "CZKHKD",
    "CZKJPY",
    "CZKSGD",
    "CZKUSD",
    "DKKAUD",
    "DKKCAD",
    "DKKCHF",
    "DKKEUR",
    "DKKGBP",
    "DKKHKD",
    "DKKJPY",
    "DKKSGD",
    "DKKUSD",
    "EURAUD",
    "EURCAD",
    "EURCHF",
    "EURCNY",
    "EURCZK",
    "EURDKK",
    "EURGBP",
    "EURHKD",
    "EURHUF",
    "EURINR",
    "EURJPY",
    "EURMXN",
    "EURNOK",
    "EURNZD",
    "EURPLN",
    "EURSAR",
    "EURSEK",
    "EURSGD",
    "EURTHB",
    "EURTRY",
    "EURTWD",
    "EURUSD",
    "EURXAG",
    "EURXAU",
    "EURZAR",
    "GBPAUD",
    "GBPCAD",
    "GBPCHF",
    "GBPCNY",
    "GBPCZK",
    "GBPDKK",
    "GBPEUR",
    "GBPHKD",
    "GBPHUF",
    "GBPINR",
    "GBPJPY",
    "GBPMXN",
    "GBPNOK",
    "GBPNZD",
    "GBPPLN",
    "GBPSAR",
    "GBPSEK",
    "GBPSGD",
    "GBPTHB",
    "GBPTRY",
    "GBPTWD",
    "GBPUSD",
    "GBPXAG",
    "GBPXAU",
    "GBPZAR",
    "HKDAUD",
    "HKDCAD",
    "HKDCHF",
    "HKDCNY",
    "HKDCZK",
    "HKDDKK",
    "HKDEUR",
    "HKDGBP",
    "HKDHUF",
    "HKDINR",
    "HKDJPY",
    "HKDMXN",
    "HKDNOK",
    "HKDNZD",
    "HKDPLN",
    "HKDSAR",
    "HKDSEK",
    "HKDSGD",
    "HKDTHB",
    "HKDTRY",
    "HKDTWD",
    "HKDUSD",
    "HKDXAG",
    "HKDXAU",
    "HKDZAR",
    "HUFAUD",
    "HUFCAD",
    "HUFCHF",
    "HUFEUR",
    "HUFGBP",
    "HUFHKD",
    "HUFJPY",
    "HUFSGD",
    "HUFUSD",
    "INRAUD",
    "INRCAD",
    "INRCHF",
    "INREUR",
    "INRGBP",
    "INRHKD",
    "INRJPY",
    "INRSGD",
    "INRUSD",
    "JPYAUD",
    "JPYCAD",
    "JPYCHF",
    "JPYCNY",
    "JPYCZK",
    "JPYDKK",
    "JPYEUR",
    "JPYGBP",
    "JPYHKD",
    "JPYHUF",
    "JPYINR",
    "JPYMXN",
    "JPYNOK",
    "JPYNZD",
    "JPYPLN",
    "JPYSAR",
    "JPYSEK",
    "JPYSGD",
    "JPYTHB",
    "JPYTRY",
    "JPYTWD",
    "JPYUSD",
    "JPYXAG",
    "JPYXAU",
    "JPYZAR",
    "MXNAUD",
    "MXNCAD",
    "MXNCHF",
    "MXNEUR",
    "MXNGBP",
    "MXNHKD",
    "MXNJPY",
    "MXNSGD",
    "MXNUSD",
    "NOKAUD",
    "NOKCAD",
    "NOKCHF",
    "NOKEUR",
    "NOKGBP",
    "NOKHKD",
    "NOKJPY",
    "NOKSGD",
    "NOKUSD",
    "NZDAUD",
    "NZDCAD",
    "NZDCHF",
    "NZDEUR",
    "NZDGBP",
    "NZDHKD",
    "NZDJPY",
    "NZDSGD",
    "NZDUSD",
    "NZDXAG",
    "NZDXAU",
    "PLNAUD",
    "PLNCAD",
    "PLNCHF",
    "PLNEUR",
    "PLNGBP",
    "PLNHKD",
    "PLNJPY",
    "PLNSGD",
    "PLNUSD",
    "SARAUD",
    "SARCAD",
    "SARCHF",
    "SAREUR",
    "SARGBP",
    "SARHKD",
    "SARJPY",
    "SARSGD",
    "SARUSD",
    "SEKAUD",
    "SEKCAD",
    "SEKCHF",
    "SEKEUR",
    "SEKGBP",
    "SEKHKD",
    "SEKJPY",
    "SEKSGD",
    "SEKUSD",
    "SGDAUD",
    "SGDCAD",
    "SGDCHF",
    "SGDCNY",
    "SGDCZK",
    "SGDDKK",
    "SGDEUR",
    "SGDGBP",
    "SGDHKD",
    "SGDHUF",
    "SGDINR",
    "SGDJPY",
    "SGDMXN",
    "SGDNOK",
    "SGDNZD",
    "SGDPLN",
    "SGDSAR",
    "SGDSEK",
    "SGDTHB",
    "SGDTRY",
    "SGDTWD",
    "SGDUSD",
    "SGDXAG",
    "SGDXAU",
    "SGDZAR",
    "THBAUD",
    "THBCAD",
    "THBCHF",
    "THBEUR",
    "THBGBP",
    "THBHKD",
    "THBJPY",
    "THBSGD",
    "THBUSD",
    "TRYAUD",
    "TRYCAD",
    "TRYCHF",
    "TRYEUR",
    "TRYGBP",
    "TRYHKD",
    "TRYJPY",
    "TRYSGD",
    "TRYUSD",
    "TWDAUD",
    "TWDCAD",
    "TWDCHF",
    "TWDEUR",
    "TWDGBP",
    "TWDHKD",
    "TWDJPY",
    "TWDSGD",
    "TWDUSD",
    "USDAUD",
    "USDCAD",
    "USDCHF",
    "USDCNY",
    "USDCZK",
    "USDDKK",
    "USDEUR",
    "USDGBP",
    "USDHKD",
    "USDHUF",
    "USDINR",
    "USDJPY",
    "USDMXN",
    "USDNOK",
    "USDNZD",
    "USDPLN",
    "USDSAR",
    "USDSEK",
    "USDSGD",
    "USDTHB",
    "USDTRY",
    "USDTWD",
    "USDXAG",
    "USDXAU",
    "USDXPT",
    "USDZAR",
    "XAGAUD",
    "XAGCAD",
    "XAGCHF",
    "XAGEUR",
    "XAGGBP",
    "XAGHKD",
    "XAGJPY",
    "XAGNZD",
    "XAGSGD",
    "XAGUSD",
    "XAGXAU",
    "XAUAUD",
    "XAUCAD",
    "XAUCHF",
    "XAUEUR",
    "XAUGBP",
    "XAUHKD",
    "XAUJPY",
    "XAUNZD",
    "XAUSGD",
    "XAUUSD",
    "XAUXAG",
    "XPTUSD",
    "ZARAUD",
    "ZARCAD",
    "ZARCHF",
    "ZAREUR",
    "ZARGBP",
    "ZARHKD",
    "ZARJPY",
    "ZARSGD",
    "ZARUSD",
];

export default function getCurrency() {
    return currency;
}