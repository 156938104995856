import React from "react";
import "../App.css";
import { useState } from "react";
import getCurrency from "../currency";

export default function Sidenav({ onSelect, currency }) {
  const [Items, setItems] = useState(getCurrency);
  return (
    <table className="table table-hover table-borderless">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Last</th>
          <th>Change</th>
          <th>Change%</th>
        </tr>
      </thead>
      <tbody>
        {Items.map((item, i) => (
          <tr
            className={item === currency ? "table-active" : null}
            key={i}
            onClick={() => onSelect(item)}
          >
            <td>{item}</td>
            <td>Last</td>
            <td>Chg</td>
            <td>Chg%</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
